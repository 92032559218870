import { Box, Button, Heading } from 'grommet'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { routes } from 'lib/routes'

import PageNotFoundIllustration from 'components/icons/PageNotFoundIllustration'
import { colors } from 'layout/themeColors/colors'

export const ErrorProviderPageNotFound = () => {
  const { t } = useTranslation()
  return (
    <Box background={colors.white} fill align="center" justify="between">
      <Box margin="180px" align="center">
        <PageNotFoundIllustration />
        <Heading textAlign="center" level="3" style={{ whiteSpace: 'pre-wrap' }}>
          {t('page-not-found')}
        </Heading>
        <Box direction="row" gap="medium">
          <Link href={routes.domain} passHref legacyBehavior>
            <Button size="large" primary label={t('go-back-to-provider-home-page')} />
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
