import { Anchor, Box, Button, Heading, Image, ResponsiveContext, Text } from 'grommet'
import getConfig from 'next/config'
import Head from 'next/head'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from 'layout/themeColors/colors'

import { ErrorProviderPageNotFound } from './ErrorProviderPageNotFound'

const {
  publicRuntimeConfig: { WEBAPP_URL, PLATFORM_NAME, WHITELABEL_ENV_IS_3VETA },
} = getConfig()

export const ErrorSubdomainNotFound = ({ currentUrl }: { currentUrl: string }) => {
  if (!WHITELABEL_ENV_IS_3VETA) {
    return <ErrorProviderPageNotFound />
  }

  const { t } = useTranslation()
  const domain = new URL(currentUrl).host
  const mainTitle = `Make ${domain} yours!`
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  return (
    <>
      <Head>
        <title>{`${mainTitle} | ${PLATFORM_NAME}`}</title>
        <meta key="og:title" property="og:title" content={mainTitle} />
      </Head>
      <Box>
        <Box
          direction={isMobile ? 'column' : 'row'}
          style={{
            background: `linear-gradient(180deg, ${colors.primaryBrand} 0%, ${colors.primaryBrand} 100%)`,
          }}
          align="center"
          justify={!isMobile ? 'between' : undefined}
          pad={{ vertical: 'large' }}
          height={{ min: 'calc(100vh - 110px)!important' }}>
          <Box
            width={isMobile ? '100%' : '60%'}
            pad={isMobile ? { horizontal: '0', left: 'medium' } : { horizontal: 'xlarge' }}
            flex="grow">
            <Heading color={colors.white} size={isMobile ? '30px' : '40px'} margin="0">
              {t('subdomain-not-found.make')}
            </Heading>
            <Heading
              color={colors.accent6}
              margin="0"
              size={isMobile ? '30px' : '40px'}
              style={{ overflowWrap: 'break-word' }}>
              {domain}
            </Heading>
            <Heading color={colors.white} size={isMobile ? '30px' : '40px'} margin="0">
              {t('subdomain-not-found.yours')}
            </Heading>
            <Text
              color={colors.white}
              margin={isMobile ? { vertical: 'medium' } : { vertical: 'medium', right: 'medium' }}
              size={isMobile ? '18px' : '24px'}>
              {t('subdomain-not-found.sign-up-description')}
            </Text>
            {isMobile && (
              <Box margin={{ bottom: 'medium' }} align="start">
                <Image src="/subdomain-not-found.png" width="100%" />
              </Box>
            )}
            <Anchor href={WEBAPP_URL + 'signup'} style={{ width: 'fit-content' }}>
              <Button
                primary
                size="large"
                style={{ backgroundColor: colors.accent6, color: colors.dark }}
                label={t('subdomain-not-found.start-for-free')}
              />
            </Anchor>
            <Box direction="row" gap="small" align="center" margin={{ top: '10px' }}>
              <Text color={colors.white} size="14px">
                {t('subdomain-not-found.already-have-account')}
              </Text>
              <Anchor href={WEBAPP_URL + 'login'} style={{ textDecoration: 'none' }}>
                <Text color={colors.accent6} size="14px" weight={600}>
                  {t('subdomain-not-found.log-in')}
                </Text>
              </Anchor>
            </Box>
          </Box>
          {!isMobile && (
            <Box width="40%" align="start">
              <Image src="/subdomain-not-found.png" width="100%" />
            </Box>
          )}
        </Box>
        <iframe
          style={{ border: 'none' }}
          src="https://3veta.com/teams/"
          frameBorder="0"
          height={isMobile ? '4700px' : '4500px'}
        />
      </Box>
    </>
  )
}
