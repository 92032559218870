import React from 'react'

import { colors } from 'layout/themeColors/colors'

const PageNotFoundIllustration = () => (
  <svg
    width="371"
    height="147"
    viewBox="0 0 371 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M186.4 135.4C218.433 135.4 244.4 109.433 244.4 77.4C244.4 45.3675 218.433 19.4 186.4 19.4C154.368 19.4 128.4 45.3675 128.4 77.4C128.4 109.433 154.368 135.4 186.4 135.4Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M68.7 117.5H5.9L0 92.1L67.2 12.1H103.5V90.6H120V117.4H103.5V142.6H68.7V117.5ZM68.7 90.8V53.6L37.8 90.8H68.7Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M318.9 117.5H256.2L250.3 92.1L317.5 12.1H353.8V90.6H370.3V117.4H353.8V142.6H319V117.5H318.9ZM318.9 90.8V53.6L288 90.8H318.9Z"
        fill="url(#paint2_linear)"
      />
      <path d="M204.8 42.7H189.8L167.5 42.8L139.5 139.2H189.8H233.3L204.8 42.7Z" fill="#DCEAFF" />
      <path
        d="M186.4 64C202.748 64 216 49.6731 216 32C216 14.3269 202.748 0 186.4 0C170.052 0 156.8 14.3269 156.8 32C156.8 49.6731 170.052 64 186.4 64Z"
        fill="#DCEAFF"
      />
      <path
        d="M215.9 32C215.9 32 206.1 38.6 186.2 38.6C166.3 38.6 156.7 32 156.7 32C156.7 32 122.5 42.6 122.5 50C122.5 57.4 160.8 64 186.2 64C211.6 64 250 57.4 250 50C249.9 42.6 215.9 32 215.9 32Z"
        fill="#5179B7"
      />
      <path
        d="M155.3 32.4C155.3 32.4 158.7 51.5 180.2 51.5C201.7 51.5 216.7 33.6 216.7 33.6C216.7 33.6 207.3 36.8 186.1 36.8C164.9 36.8 155.3 32.4 155.3 32.4Z"
        fill="#4971AA"
      />
      <path
        d="M186.4 146.6C212.302 146.6 233.3 143.242 233.3 139.1C233.3 134.958 212.302 131.6 186.4 131.6C160.498 131.6 139.5 134.958 139.5 139.1C139.5 143.242 160.498 146.6 186.4 146.6Z"
        fill="#AECEF4"
      />
      <path
        d="M205.9 99.6L187.5 114.5C187 114.9 186.2 114.8 185.8 114.2L170.2 91.8C169.8 91.2 169.9 90.4 170.4 90L185.5 77.7L193.1 79.1L206 97.7C206.5 98.4 206.4 99.2 205.9 99.6Z"
        fill="#608CCC"
      />
      <path
        d="M185.6 77.8L193.2 79.2L189.9 82.1C189.5 82.5 188.8 82.4 188.5 81.9L185.6 77.8Z"
        fill="#4971AA"
      />
      <path
        d="M220.3 87.2L213.3 88C212.9 88 212.8 87.5 213.1 87.3C214.4 86.3 216.3 84.5 217.6 81.1C218.9 77.6 218.7 75.6 218.5 74.9C218.5 74.7 218.5 74.6 218.7 74.5L225.1 70.8C225.3 70.7 225.6 70.8 225.6 71.1C225.8 72.3 226 75.5 224.6 79.5C223.1 83.7 221.1 86.6 220.7 87.2C220.5 87.1 220.4 87.2 220.3 87.2Z"
        fill={colors.white}
      />
      <path
        d="M153.4 115.7C152.6 117.9 150.2 125.9 156.1 131C156.4 131.3 156.9 131.3 157.3 131.2L166.2 126.8C166.9 126.5 167.1 125.6 166.6 125C165.2 123 162.7 118.7 163.7 114.3C163.9 113.4 163.2 112.6 162.4 112.8L154.2 114.9C153.8 115.1 153.5 115.3 153.4 115.7Z"
        fill={colors.white}
      />
      <path
        d="M158.6 56.4C162.908 56.4 166.4 52.6392 166.4 48C166.4 43.3608 162.908 39.6 158.6 39.6C154.292 39.6 150.8 43.3608 150.8 48C150.8 52.6392 154.292 56.4 158.6 56.4Z"
        fill="#2F4B92"
      />
      <path
        d="M214.2 56.4C218.508 56.4 222 52.6392 222 48C222 43.3608 218.508 39.6 214.2 39.6C209.892 39.6 206.4 43.3608 206.4 48C206.4 52.6392 209.892 56.4 214.2 56.4Z"
        fill="#2F4B92"
      />
      <path
        d="M214.2 54.5C217.514 54.5 220.2 51.5899 220.2 48C220.2 44.4101 217.514 41.5 214.2 41.5C210.886 41.5 208.2 44.4101 208.2 48C208.2 51.5899 210.886 54.5 214.2 54.5Z"
        fill="#DCEAFF"
      />
      <path
        d="M186.3 59.9C190.608 59.9 194.1 56.1392 194.1 51.5C194.1 46.8608 190.608 43.1 186.3 43.1C181.992 43.1 178.5 46.8608 178.5 51.5C178.5 56.1392 181.992 59.9 186.3 59.9Z"
        fill="#2F4B92"
      />
      <path
        d="M186.2 40.4C166 40.4 156.2 33.8 155.8 33.6C155 33.1 154.8 31.9 155.3 31.1C155.8 30.2 156.8 30 157.6 30.5C157.7 30.6 167.1 36.8 186.2 36.8C205.3 36.8 215 30.6 215 30.5C215.8 30 216.8 30.2 217.3 31.1C217.8 32 217.5 33.1 216.7 33.6C216.4 33.8 206.3 40.4 186.2 40.4Z"
        fill="#608CCC"
      />
      <path
        d="M214.2 41.6C213.9 41.6 213.6 41.6 213.3 41.7L208.4 50C208.8 51.4 209.6 52.6 210.7 53.4L217.1 42.5C216.3 41.9 215.3 41.6 214.2 41.6Z"
        fill={colors.white}
      />
      <path
        d="M218.4 43.4L212.1 54.1C212.7 54.4 213.4 54.5 214.2 54.5C214.3 54.5 214.4 54.5 214.5 54.5L219.8 45.5C219.4 44.7 219 44 218.4 43.4Z"
        fill={colors.white}
      />
      <path
        d="M186.2 58C189.514 58 192.2 55.0899 192.2 51.5C192.2 47.9101 189.514 45 186.2 45C182.886 45 180.2 47.9101 180.2 51.5C180.2 55.0899 182.886 58 186.2 58Z"
        fill="#DCEAFF"
      />
      <path
        d="M186.2 45C185.9 45 185.6 45 185.3 45.1L180.4 53.4C180.8 54.8 181.6 56 182.7 56.8L189.1 45.9C188.3 45.3 187.3 45 186.2 45Z"
        fill={colors.white}
      />
      <path
        d="M190.4 46.9L184.1 57.6C184.7 57.9 185.4 58 186.2 58C186.3 58 186.4 58 186.5 58L191.8 49C191.4 48.2 191 47.5 190.4 46.9Z"
        fill={colors.white}
      />
      <path
        d="M158.6 54.5C161.914 54.5 164.6 51.5899 164.6 48C164.6 44.4101 161.914 41.5 158.6 41.5C155.286 41.5 152.6 44.4101 152.6 48C152.6 51.5899 155.286 54.5 158.6 54.5Z"
        fill="#DCEAFF"
      />
      <path
        d="M158.6 41.6C158.3 41.6 158 41.6 157.7 41.7L152.8 50C153.2 51.4 154 52.6 155.1 53.4L161.5 42.5C160.6 41.9 159.6 41.6 158.6 41.6Z"
        fill={colors.white}
      />
      <path
        d="M162.8 43.4L156.5 54.1C157.1 54.4 157.8 54.5 158.6 54.5C158.7 54.5 158.8 54.5 158.9 54.5L164.2 45.5C163.8 44.7 163.3 44 162.8 43.4Z"
        fill={colors.white}
      />
      <path
        d="M186.4 0C181.2 0 176.4 1.4 172.1 4C172.5 3.8 186 -1.9 199.6 6.2C213.1 14.4 215.8 29.6 215.9 30.1C215 13.3 202.1 0 186.4 0Z"
        fill="#AECEF4"
      />
      <path
        d="M249.9 50C249.9 43.5 223.3 34.4 217.1 32.4C217.1 32.4 243.9 43.4 244.5 48.9C245.1 54.5 230.8 58.9 230.8 58.9L240.5 56.3C246.3 54.4 249.9 52.3 249.9 50Z"
        fill="#608CCC"
      />
      <path
        d="M122.5 50C122.5 43.5 149.1 34.4 155.3 32.4C155.3 32.4 128.5 43.4 127.9 48.9C127.3 54.5 141.6 58.9 141.6 58.9L131.9 56.3C126.1 54.4 122.5 52.3 122.5 50Z"
        fill="#608CCC"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="128.391"
        y1="77.4163"
        x2="244.346"
        y2="77.4163"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#BEDDFE" />
        <stop offset="0.2337" stopColor="#C1DBFD" />
        <stop offset="0.3268" stopColor="#C1DBFD" />
        <stop offset="1" stopColor="#F1BFEF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="59.9731"
        y1="12.1678"
        x2="59.9731"
        y2="142.665"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#BEDDFE" />
        <stop offset="0.2337" stopColor="#C1DBFD" />
        <stop offset="0.3268" stopColor="#C1DBFD" />
        <stop offset="1" stopColor="#F1BFEF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="310.22"
        y1="12.1678"
        x2="310.22"
        y2="142.665"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#BEDDFE" />
        <stop offset="0.2337" stopColor="#C1DBFD" />
        <stop offset="0.3268" stopColor="#C1DBFD" />
        <stop offset="1" stopColor="#F1BFEF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="370.2" height="146.6" fill={colors.white} />
      </clipPath>
    </defs>
  </svg>
)
export default PageNotFoundIllustration
